import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-white text-gray-800 pt-10 pb-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Top Section */}
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start space-y-8 lg:space-y-0">
          {/* Logo and Description */}
          <div className="text-center lg:text-left">
            <div className="flex items-center justify-center lg:justify-start mb-4">
              {/* Logo Placeholder */}
              <div className="w-8 h-8 bg-blue-500 rounded-full"></div>
              <span className="ml-3 text-lg font-semibold">Snaazr</span>
            </div>
            <p className="text-gray-600">Revolutionizing e-commerce with video-based shopping experiences.</p>
            <p className="text-gray-500">Snaazr, 2024.</p>
          </div>

          {/* Links */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-8 text-center lg:text-left">
            <div>
              <h5 className="font-semibold mb-2 text-gray-700">Platform</h5>
              <ul>
                <li><button onClick={() => {}} className="hover:underline text-gray-600 hover:text-gray-800">How It Works</button></li>
                <li><button onClick={() => {}} className="hover:underline text-gray-600 hover:text-gray-800">Join as Seller</button></li>
                <li><button onClick={() => {}} className="hover:underline text-gray-600 hover:text-gray-800">Join as Influencer</button></li>
              </ul>
            </div>

            <div>
              <h5 className="font-semibold mb-2 text-gray-700">Company</h5>
              <ul>
                <li><button onClick={() => {}} className="hover:underline text-gray-600 hover:text-gray-800">About Us</button></li>
                <li><button onClick={() => {}} className="hover:underline text-gray-600 hover:text-gray-800">Contact</button></li>
              </ul>
            </div>

            <div>
              <h5 className="font-semibold mb-2 text-gray-700">Support</h5>
              <ul>
                <li><button onClick={() => {}} className="hover:underline text-gray-600 hover:text-gray-800">Help Center</button></li>
                <li><button onClick={() => {}} className="hover:underline text-gray-600 hover:text-gray-800">FAQs</button></li>
                <li><button onClick={() => {}} className="hover:underline text-gray-600 hover:text-gray-800">Terms of Service</button></li>
              </ul>
            </div>
          </div>
        </div>

        {/* Social Media Section */}
        <div className="mt-6 flex justify-center lg:justify-start space-x-6">
          <a href="#" aria-label="Facebook" className="text-gray-600 hover:text-blue-500 transition-colors duration-300">
            <FaFacebook size={24} />
          </a>
          <a href="https://x.com/snaazr" aria-label="Twitter" className="text-gray-600 hover:text-blue-400 transition-colors duration-300">
            <FaTwitter size={24} />
          </a>
          <a href="https://www.instagram.com/snaazrofficial/" aria-label="Instagram" className="text-gray-600 hover:text-pink-500 transition-colors duration-300">
            <FaInstagram size={24} />
          </a>
          <a href="https://www.linkedin.com/company/snaazr/" aria-label="LinkedIn" className="text-gray-600 hover:text-blue-600 transition-colors duration-300">
            <FaLinkedin size={24} />
          </a>
        </div>

        {/* Bottom Section */}
        <div className="border-t border-gray-300 mt-6 pt-6 flex flex-col lg:flex-row justify-between items-center">
          <p className="text-center lg:text-left text-gray-500 mb-4 lg:mb-0">
            {/* © 2024 Snaazr Inc. All rights reserved. */}
          </p>
          <a href="#" className="text-blue-500 hover:underline">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { TailSpin } from 'react-loader-spinner'; // Importing a loading spinner

const Waitlist = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [submitted, setSubmitted] = useState(false); // Submission state

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true

    const serviceID = 'service_kges9he';
    const templateID = 'template_4dfw585';
    const userID = 'p27N5UaaBhGZyMQMP';

    const templateParams = {
      user_email: email, // Email entered by the user
    };

    emailjs.send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setMessage('Thank you! You have successfully joined the waitlist.');
        setSubmitted(true); // Set submitted to true
        setEmail(''); // Clear input field after submission
      })
      .catch((error) => {
        console.error('FAILED...', error);
        setMessage('Something went wrong. Please try again later.');
      })
      .finally(() => {
        setLoading(false); // Set loading to false after completion
      });
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left Section */}
      <div className="flex flex-col justify-center items-start w-full md:w-1/2 bg-gray-50 p-6 md:p-10 space-y-4 md:space-y-6">
        {/* Logo */}
        <div className="text-2xl md:text-3xl font-bold">Snaazr</div>
        {/* Launch Date */}
        <div className="bg-gray-200 text-gray-600 text-xs md:text-sm px-3 py-1 rounded-full">
          LAUNCHING VERY SOON!
        </div>
        {/* Heading */}
        <h1 className="text-2xl md:text-4xl font-bold text-gray-900 leading-snug md:leading-tight">
          Ready to explore The Future of Shopping?
        </h1>
        {/* Subtext */}
        <p className="text-gray-600 text-base md:text-lg">
          Picture this: shopping that’s not just a chore but an adventure! With Snaazr, dive into a vibrant world where you discover products through short, captivating videos. Are you ready to revolutionize your shopping spree?
        </p>

        {/* Input Form */}
        {!submitted && ( // Conditionally render the form
          <form onSubmit={sendEmail} className="flex items-center w-full">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="flex-grow p-3 md:p-4 rounded-l-lg border border-gray-300 text-base md:text-lg h-10 md:h-12 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="bg-black text-white px-4 md:px-6 py-2 rounded-r-lg font-semibold text-base md:text-lg h-10 md:h-12 transition-transform duration-200 transform hover:scale-105 active:scale-95 focus:outline-none"
              disabled={loading} // Disable button when loading
            >
              {loading ? (
                <TailSpin color="#fff" height={24} width={24} /> // Show loading spinner
              ) : (
                'Join now'
              )}
            </button>
          </form>
        )}

        {/* Feedback Message */}
        {message && <p className="text-green-500 mt-4">{message}</p>}

        {/* Already joined */}
        <div className="flex items-center space-x-2 md:space-x-3">
          <p className="text-gray-600 text-xs md:text-sm">163+ people have already joined</p>
        </div>
      </div>

      {/* Right Section with Background Image and Gradient Overlay */}
      <div className="hidden md:block md:w-1/2 h-full relative">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            // backgroundImage: 'url(https://via.placeholder.com/1200)', // Replace with your image URL
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-gray-900 opacity-75"></div>
        </div>
      </div>
    </div>
  );
};

export default Waitlist;
